import React from 'react';
import data from '../data/paomakeup';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OurServices = () => {
    return (
        <Container className="my-5">
            <h1>Nuestros servicios</h1>
            <Row>
                {data.our_services.slice(0, 6).map((service, index) => (
                    <Col key={index} sm={12} md={4} className="mt-4">
                        <Card className='border-0 mx-lg-4 animate__animated animate__fadeInUp'>
                            <Card.Img className='image-class' variant="top" src={service.image} />
                            <Card.Body>
                                <h3 className="card-title">{service.title}</h3>
                                <Card.Text>
                                    {service.description}
                                </Card.Text>
                                <Link to={`nuestros-servicios/precios/${service.service_slug}`} className="btn btn-primary">
                                    Ver Precios
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default OurServices;
