import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { format } from 'date-fns';
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";
import { Spinner } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import data from '../data/paomakeup';
registerLocale('es', es);

const ScheduleCall = () => {
    
    useEffect(() => emailjs.init("OR-hFrEcD28bt5k1j"), []);
      
    const [alertMessage, setAlertMessage] = useState('');
    const [selected, setSelected] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { register, handleSubmit, formState: {errors} } = useForm();
    const handleError = () => {
        setAlertMessage('Debe completar todos los campos requeridos.');
        setShowAlert(true);
    };

    const [selectedLocation, setSelectedLocation] = useState();
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date());

    const registerOptions = {
        fullName: {
            required: 'El nombre es requerido',
            maxLength: {
                value: 30,
                message: 'El nombre no puede tener mas de 30 caracteres'
            },
            minLength: {
                value: 3,
                message: 'El nombre debe tener al menos 3 caracteres'
            }
        },
        clientEmail: {
            required: 'El email es requerido',
            pattern: {
                value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$/,
                message: 'El email no es válido'
            }
        },
        phoneNumber: {
            required: 'El teléfono es requerido',
            pattern: {
                value: /^[0-9]*$/,
                message: 'El teléfono no es válido'
            }
        },
        contactVia: {
            required: 'La vía de contacto es requerida',
        },
        receiveService: {
            required: 'El lugar de recepción del servicio es requerido',
        },
        desiredService: {
            required: 'El servicio deseado es requerido',
        },
        contactHour: {
            required: 'El horario de contacto es requerido',
        },
        address: {
            required: 'La dirección es requerida',
        },
    };

    const sendEmail = async (data) => {
        const serviceId = "service_yh80bao";
        const templateId = "template_8db5huo";

        const error = handleError();
        if (error) {
            setAlertMessage('Ha ocurrido un error, intente mas tarde o escriba al whatsapp 809-605-0699.');
            return;
        }
        
        try {
            console.log('entro a enviar el correo try')
            setAlertMessage('Enviando mensaje...');
            setShowAlert(true);
            setIsSubmitting(true);
            await emailjs.send(serviceId, templateId, {
                fullName: data.fullName,
                email: data.clientEmail,
                desiredService: data.desiredService,
                desiredDate: format(date, 'dd/MM/yyyy'),
                desiredTime: format(time, 'hh:mm aa'),
                peopleCount: data.peopleCount,
                phoneNumber: data.phoneNumber,
                contactVia: data.contactVia,
                contactHour: data.contactHour,
                receiveService: data.receiveService,
                address: data.address,
                message: data.generalMessage,
            },
                'OR-hFrEcD28bt5k1j'
            );
        } catch (error) {
            setAlertMessage('Ha ocurrido un error, intente mas tarde o escriba al whatsapp 809-605-0699.');
        } finally {
            setAlertMessage('El mensaje se ha enviado correctamente.');
            setIsSubmitting(false);
            setIsSubmitted(true);
        }
    };

    return (
        <Container className="justify-content-center align-items-center vh-auto my-5">
            <Helmet>
                <title>Agende una cita con nosotros - {data.title}</title>
                <meta name="description" content={data.description} />
                <meta name="keywords" content={data.our_services_price_keywords} />
                <meta property="og:title" content={`Agende una cita con nosotros - ${data.title}`} />
                <meta property="og:type" content="article"/>
                <meta property="og:url" content="/agendar-cita" />
                <meta property="og:site_name" content="PaoMakeup Beauty Studio" />
                <meta property="og:description" content={data.description} />
            </Helmet>
            <Row className='mb-3 mb-sm-5'>
                <h1>
                    Agende una cita con nosotros
                </h1>
            </Row>
            <Row>
                <Col sm={6}>
                    <Row className='mb-3'>
                    {showAlert && (
                        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                            {alertMessage}
                        </Alert>
                    )}
                    {isSubmitted ? (
                        <Container className='text-center mt-sm-5 animate__animated animate__fadeInUp'>
                            <h1 className='display-4'>Gracias por agendar su cita con nosotros</h1>
                            <p className='display-6'>En breve nos comunicaremos con usted.</p>
                        </Container>
                    ) : (
                        <Form className='text-start col-12 schedule-form px-sm-5' onSubmit={handleSubmit(sendEmail, handleError)}>
                            <Row className='mb-3'>
                                <Form.Group as={Col} sm={6} controlId="formGridName">
                                    <Form.Label as="legend">Nombres</Form.Label>

                                    <Form.Control 
                                        type="text" 
                                        placeholder="Ingrese su nombre" 
                                        {...register("fullName", registerOptions.fullName)}
                                    /> 
                                    <small className="text-danger">
                                        {errors?.fullName && errors.fullName.message}
                                    </small>
                                </Form.Group>

                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                    <Form.Label as="legend">Correo</Form.Label>
                                    <Form.Control 
                                        name="clientEmail" 
                                        type='email'
                                        placeholder="Correo"
                                        {...register("clientEmail", registerOptions.clientEmail)}
                                    />
                                    <small className="text-danger">
                                        {errors?.clientEmail && errors.clientEmail.message}
                                    </small>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col} sm={6} controlId="formGridPhone">
                                    <Form.Label as="legend">Teléfonos de Contacto (Fijo y Móvil)</Form.Label>
                                    <Form.Control 
                                        name="phoneNumber" 
                                        type="number"
                                        placeholder="Ingrese su número de contácto"
                                        {...register("phoneNumber", registerOptions.phoneNumber)}
                                    />
                                    <small className="text-danger">
                                        {errors?.phoneNumber && errors.phoneNumber.message}
                                    </small>
                                </Form.Group>

                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col} sm={6} controlId="formGridVia">
                                    <fieldset>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label as="legend">
                                                ¿Desea ser contactada vía?
                                            </Form.Label>
                                            <Col>
                                                <Form.Check
                                                type="radio"
                                                label="Whatsapp"
                                                name="contactVia"
                                                value="Whatsapp"
                                                {...register("contactVia", registerOptions.contactVia)}
                                                />
                                                <Form.Check
                                                type="radio"
                                                label="Llamada Telefónica"
                                                value="Llamada Telefónica"
                                                name="contactVia"
                                                {...register("contactVia", registerOptions.contactVia)}
                                                />
                                            </Col>
                                            <small className="text-danger">
                                                {errors?.contactVia && errors.contactVia.message}
                                            </small>
                                        </Form.Group>
                                    </fieldset>
                                </Form.Group>
                                <Form.Group as={Col} sm={6} className="mb-3" controlId="formHorizontalCheck">
                                    <Form.Label as="legend">
                                        ¿Dónde desea realizar el servicio?
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            label="Estudio"
                                            value="Estudio"
                                            name="receiveService"
                                            {...register("receiveService", registerOptions.receiveService)}
                                            onChange={() => setSelectedLocation(false)}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="A Domicilio"
                                            value="A Domicilio"
                                            name="receiveService"
                                            {...register("receiveService", registerOptions.receiveService)}
                                            onChange={() => setSelectedLocation(true)}
                                        />
                                    </Col>
                                    <small className="text-danger">
                                        {errors?.receiveService && errors.receiveService.message}
                                    </small>
                                    {selectedLocation && (
                                        <>
                                            <Form.Label column sm={12}>
                                                Dirección donde desea recibir el servicio
                                            </Form.Label>
                                            <Col sm={12}>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Dirección" 
                                                    name="address"
                                                    {...register("address", selectedLocation && registerOptions.address)}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </Form.Group>

                            </Row>
                            <Row>
                            <Form.Group as={Col} sm={6} className="mb-3" controlId="formHorizontalCheck">
                                    <Form.Label as="legend">
                                        Servicio que solicita
                                    </Form.Label>
                                    
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Maquillaje"
                                        value="Maquillaje"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Peinado/Ondas"
                                        value="Peinado/Ondas"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Lavado y Secado"
                                        value="Lavado y Secado"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Cejas Perfectas (Depilación y Tintado)"
                                        value="Cejas Perfectas (Depilación y Tintado)"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Extensiones de Pestañas (Pelo a Pelo/Volumen)"
                                        value="Extensiones de Pestañas (Pelo a Pelo/Volumen)"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Pintura de Uñas"
                                        value="Pintura de Uñas"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Microblading"
                                        value="Microblading"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Shading Digital"
                                        value="Shading Digital"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Micropigmentacion de Labios"
                                        value="Micropigmentacion de Labios"
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Otros "
                                        value="Otros "
                                        name="desiredService"
                                        {...register("desiredService", registerOptions.desiredService)}
                                        />
                                    </Col>
                                    
                                    <small className="text-danger">
                                        {errors?.desiredService && errors.desiredService.message}
                                    </small>
                                </Form.Group>
                                <Form.Group as={Col} sm={6} className="mb-3" controlId="formHorizontalPassword">
                                        <Form.Label as="legend">
                                            Fecha y Hora de su cita
                                        </Form.Label>
                                        <Col className='mb-3'>
                                            <DatePicker
                                                selected={date}
                                                onChange={(newDate) => setDate(newDate)}
                                                dateFormat="MMMM d, yyyy"
                                                className='form-control'
                                                locale="es"
                                             
                                            />
                                        </Col>
                                        <Col className='mb-3'>
                                            <DatePicker
                                                selected={time}
                                                onChange={(newTime) => setTime(newTime)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                className='form-control'
                                                minTime={new Date().setHours(8,0)}
                                                maxTime={new Date().setHours(18,30)}
                      
                                            />
                                        </Col>
                                        <Form.Label as="legend">
                                            Cantidad de personas
                                        </Form.Label>
                                        <Col>
                                            <Form.Control 
                                                name="peopleCount" 
                                                type="number" 
                                                placeholder="Cantidad"
                                                {...register("peopleCount")}
                                            />
                                        </Col>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col} controlId="formGridMessage">
                                    <Form.Label as="legend">Detalle debajo alguna solicitud adicional para su cita. Como por ejemplo, si desea sus servicios con alguna artista en especial.</Form.Label>
                                    <Form.Control 
                                        name="generalMessage" 
                                        as="textarea" 
                                        rows={4} 
                                        {...register("generalMessage")}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className='mb-3 justify-content-center'>
                                <Form.Group as={Row} sm={10} controlId="formSend">
                                    {isSubmitting ? <Spinner animation="border" variant="primary" /> : (
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            Enviar
                                        </Button>
                                    )}
                                </Form.Group>
                            </Row>            
                    
                        </Form>
                    )}
                    </Row>
                </Col>
                <Col sm={6} >
                    <img className='w-50 mb-5' src='../assets/paomakeup/images/contact-us.jpg' alt='Contactenos Icon' />
                    <Row className='justify-content-center text-center'>
                        <Col sm={6}>
                            <ul className='text-md-start list-unstyled custom-list'>
                                <li>
                                <FontAwesomeIcon className='fa-2x' icon={faPhone} /> <span className='mx-4'> 809-547-4779</span>
                                </li>
                                <li>
                                <FontAwesomeIcon className='fa-2x' icon={faWhatsapp} /> <span className='mx-4'> 829-729-4779</span>
                                </li>
                                <li>
                                <FontAwesomeIcon className='fa-2x' icon={faEnvelope} /> <span className='mx-4'> info@paomakeup.com</span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className='justify-content-center text-center'>
                        <a href="/cotiza-tu-evento" className='btn btn-primary w-50 mx-3 my-2'>Cotiza tu evento</a>
                        <a href="/evaluacion-para-maquillaje-semipermanente" className='btn btn-primary w-50 mx-3 my-3'>Evalúate gratis</a>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-3 mt-sm-5'>
                <p className='mt-3 mt-sm-5'>Nuestro equipo de Servicio al Cliente le estará contactando a la mayor brevedad para enviarle la confirmación de su cita vía WhatsApp o llamada telefónica. Debe esperar esta confirmación para asegurar su cita. En caso de no ser contactada en 24hrs favor escribirnos al 829.729.4779. Gracias por preferirnos!</p>
            </Row>
        </Container>
    );
};

export default ScheduleCall;
