import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card"; 
import Row from "react-bootstrap/Row"; 
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
// import Masonry from 'react-masonry-css'; // desktop
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"; // mobile
import "react-multi-carousel/lib/styles.css";
import data from "../data/paomakeup";
import { Helmet } from "react-helmet";

const breakpointColumnsObj = {
    default: 2,
    1100: 3,
    700: 1,
    385: 1
  };

const OurServicesPrices = () => {

    const servicePriceElements = data.our_services_price.map((service, index) => (
        <div key={index} className="custom-service animate__animated animate__bounceInRight">
            <Card bg={service.bgcolor.toLowerCase()} className="border-0" style={{ width: '100%' }}>
                <Card.Header>{service.service_name}</Card.Header>
                <ListGroup variant="flush">
                    {service.services.map((item, index) => (
                        <ListGroup.Item key={index} style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <span>{item.name}</span>
                            <span>{item.price}</span>    
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Card>
        </div>
    ));

    return (
        <Container className="my-5 g-0">
            <Helmet>
                <title>{data.our_services_price_title} - {data.title}</title>
                <meta name="description" content={data.our_services_price_description} />
                <meta name="keywords" content={data.our_services_price_keywords} />
                <meta property="og:title" content={data.our_services_price_title} />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://www.paomakeup.com/nuestros-servicios/precios" />
                <meta property="og:site_name" content="PaoMakeup Beauty Studio" />
                <meta property="og:description" content={data.our_services_price_description} />
            </Helmet>
            <h1 className="mb-5 mx-4 animate__animated animate__pulse">Conoce nuestros servicios y precios</h1>
            {/* Masonry Desktop */}
            {/* <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {servicePriceElements}
            </Masonry> */}

            {/* Masonry Mobile responsive */}
            <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                <Masonry>
                    {servicePriceElements}
                </Masonry>
            </ResponsiveMasonry>


        </Container>
    );
}

export default OurServicesPrices;
