// Create component for home page carousel
import React from "react";
import {Carousel, Row, Col, Image} from 'react-bootstrap';
import data from '../data/paomakeup';

const HomeCarousel = () => {
    const [index, setIndex] = React.useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    }


    return (
        <div className="carousel-container d-flex align-items-center">
            <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
                {data.homepage_carousel.map((item) => (
                    <Carousel.Item key={item.id}>
                        <Row className="align-items-center justify-content-center text-center">
                            <Col xs={12} md={3} className="offset-md-2 d-flex align-items-center text-center text-md-start animate__animated animate__fadeInDown">
                                <div className="mt-4">
                                    <h1 className="mb-4">{item.title}</h1>
                                    <h5 className="mx-2 mx-md-0">{item.description}</h5>
                                </div>
                            </Col>
                            <Col xs={12} md={7} className="d-flex align-items-center justify-content-center animate__animated animate__fadeInRight">
                                <div className="carousel-container-image">
                                    <Image src={item.image} alt={item.alt} className="carousel-image img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}

export default HomeCarousel;
