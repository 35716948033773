import React from 'react';
import { Container, Row, Col, CardImg } from 'react-bootstrap';
import data from '../data/paomakeup';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";

const AboutUs = () => {
    return (
        <Container className="my-5">
            <Helmet>
                <title>Conoce nuestra empresa - {data.title}</title>
                <meta name="description" content="Somos un equipo de profesionales enfocados en la belleza. Hemos creado este centro ofreciendo la mejor asistencia en el área de maquillaje, peinados y otros servicios que demanda la mujer de hoy en día." />
                <meta name="keywords" content={data.our_services_price_keywords} />
                <meta property="og:title" content="Conoce nuestra empresa" />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="/nosotros" />
                <meta property="og:site_name" content="PaoMakeup Beauty Studio" />
                <meta property="og:description" content="Somos un equipo de profesionales enfocados en la belleza. Hemos creado este centro ofreciendo la mejor asistencia en el área de maquillaje, peinados y otros servicios que demanda la mujer de hoy en día." />
            </Helmet>
            <Row className="mb-5">
                <Col className="col-12 col-md-6 animate__animated animate__backInLeft">
                    <CardImg className="vh-50" src={data.aboutus_image} alt={data.aboutus_title} />
                </Col>
                <Col className="justify-content-start col-12 col-md-5 animate__animated animate__backInRight">
                    <h1 className="text-start">{data.aboutus_title}</h1>
                    <p className="text-start fs-5">{ReactHtmlParser(data.aboutus_body)}</p>
                </Col>
            </Row>
        </Container>
    );
};

export default AboutUs;
