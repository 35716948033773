import React from 'react';

const WhatsappIcon = () => {
    return (
        <a href="https://api.whatsapp.com/send?phone=18297294779&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20servicios%20de%20..." target="_blank" rel="noreferrer">
            <img class="whatsapp-icon" src="/assets/paomakeup/images/whatsapp.png" alt="Whatsapp"
                 />
        </a>
    );
};

export default WhatsappIcon;