import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { format } from 'date-fns';
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";
import { Spinner } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import data from '../data/paomakeup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServiceEvaluationForm = () => {
    
    useEffect(() => emailjs.init("OR-hFrEcD28bt5k1j"), []);
      
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedService, setSelectedService] = useState([]);
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date());
    const [checkedOptions, setCheckedOptions] = useState();

    const { register, handleSubmit, formState: {errors} } = useForm();
    const handleError = () => {
        setAlertMessage('Debe completar todos los campos requeridos.');
        setShowAlert(true);
    };

    const handleCheckboxChange = (option) => {
        setCheckedOptions(prevState => {
            const newState = { ...prevState };
            if (newState[option]) {
                delete newState[option];
                setSelectedService(null);
            } else {
                newState[option] = true;
                setSelectedService(option);
            }
            return newState;
        });
    };
    console.log('checkedOptions', checkedOptions);

    const registerOptions = {
        fullName: {
            required: 'El nombre es requerido',
            maxLength: {
                value: 30,
                message: 'El nombre no puede tener mas de 30 caracteres'
            },
            minLength: {
                value: 3,
                message: 'El nombre debe tener al menos 3 caracteres'
            }
        },
        clientEmail: {
            required: 'El email es requerido',
            pattern: {
                value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$/,
                message: 'El email no es válido'
            }
        },
        phoneNumber: {
            required: 'El teléfono es requerido',
            pattern: {
                value: /^[0-9]*$/,
                message: 'El teléfono no es válido'
            }
        },
        skinType: {
            required: 'Tipo de piel es requerido',
        },
        lastBrowsProcess: {
            required: 'Este campo es requerido',
        },
        checkedOptions: {
            required: 'El servicio a evaluar debe ser seleccionado',
        },
        conditions: {
            required: 'Este campo es requerido.',
        },
        contactHour: {
            required: 'El horario de contacto es requerido',
        },
    };

    const conditionsOptions = [
        "Hemofilia",
        "Diabetes",
        "Cáncer o bajo quimioterapia",
        "Problemas Cardiovasculares",
        "Hipertensión",
        "Eccema",
        "Psoriasis",
        "Dermatitis Ceborréica",
        "Toma Anticoagulantes",
        "Embarazada o Lactando",
        "Cicatrización Queloides",
        "Herpes",
        "Enfermedades Autoinmunes",
        "Ninguna de las anteriores",
    ];

    const skinTypeOptions = [
        "Grasa",
        "Muy Grasa",
        "Seca",
        "Mixta",
        "Normal",
    ];

    const lastBrowsProcessOptions = [
        "Microblading",
        "Tatuaje",
        "Sombreado o Shading Digital",
        "Implante de pelo",
        "Ninguna de las anteriores",
    ];

    const sendEmail = async (data) => {
        const serviceId = "service_iks7h7a";
        const templateId = "template_ggjbltv";

        const error = handleError();
        if (error) {
            setAlertMessage('Ha ocurrido un error, intente mas tarde o escriba al whatsapp 809-605-0699.');
            return;
        }
        
        try {
            console.log('entro a enviar el correo try')
            setAlertMessage('Enviando mensaje...');
            setShowAlert(true);
            setIsSubmitting(true);
            await emailjs.send(serviceId, templateId, {
                fullName: data.fullName,
                selectedServices: Object.keys(checkedOptions).filter(serviceName => checkedOptions[serviceName]).join(', '),
                email: data.clientEmail,
                phoneNumber: data.phoneNumber,
                skinType: data.skinType,
                currentDate: format(date, 'dd/MM/yyyy HH:mm'),
                lastBrowsProcess: data.lastBrowsProcess,
                conditions: data.conditions,
                message: data.generalMessage,
            },
                'T_J-KCWbmYMA0ibuB'
            );
        } catch (error) {
            setAlertMessage('Ha ocurrido un error, intente mas tarde o escriba al whatsapp 809-605-0699.');
        } finally {
            setAlertMessage('El mensaje se ha enviado correctamente.');
            setIsSubmitting(false);
            setIsSubmitted(true);
        }
    };

    const filteredServices = selectedService ? data.permanent_makeup_services.filter(service => service.name === selectedService.name) : [];
    console.log('filteredServices', filteredServices);
    return (
        <Container className="justify-content-center align-items-center vh-auto my-5">
            <Helmet>
                <title>Evaluación para Servicio de Maquillaje Permanente - {data.title}</title>
                <meta name="description" content={data.description} />
                <meta name="keywords" content={data.our_services_price_keywords} />
                <meta property="og:title" content={`Evaluación para Servicio de Maquillaje Permanente - ${data.title}`} />
                <meta property="og:type" content="article"/>
                <meta property="og:url" content="/agendar-cita" />
                <meta property="og:site_name" content="PaoMakeup Beauty Studio" />
                <meta property="og:description" content={data.description} />
            </Helmet>
            <Row className='mb-3 mb-sm-5'>
                <h1>
                    Evaluación para Servicio de Maquillaje Permanente
                </h1>
            </Row>
            {showAlert && (
                <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            )}
            {isSubmitted ? (
                <Container className='text-center mt-sm-5 animate__animated animate__fadeInUp'>
                    <h1 className='display-4'>Gracias por su correo.</h1>
                    <p className='display-6'>En breve nos comunicaremos con usted.</p>
                </Container>
            ) : (
                <Row>
                    <Col className='order-1 order-md-0'>
                        <Row className='mb-3'>
                            <Form className='text-start col-12 schedule-form px-sm-5' onSubmit={handleSubmit(sendEmail, handleError)}>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} sm={6} controlId="formGridName">
                                        <Form.Label className="mb-1 mb-md-3" as="legend">Nombre y Apellido</Form.Label>

                                        <Form.Control 
                                            type="text" 
                                            className='mb-4 mb-md-0'
                                            placeholder="Ingrese su nombre" 
                                            {...register("fullName", registerOptions.fullName)}
                                        /> 
                                        <small className="text-danger">
                                            {errors?.fullName && errors.fullName.message}
                                        </small>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6} controlId="formGridName">
                                        <Form.Label className="mb-1 mb-md-3" as="legend">Servicios seleccionados</Form.Label>
                                        {checkedOptions && Object.keys(checkedOptions).filter(serviceName => checkedOptions[serviceName]).join(', ')}
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} sm={6} controlId="formGridPhone">
                                        <Form.Label className="mb-1 mb-md-3" as="legend">Teléfono / Whatsapp</Form.Label>
                                        <Form.Control 
                                            name="phoneNumber" 
                                            className='mb-4 mb-md-0'
                                            type="number"
                                            placeholder="Ingrese su número de contácto"
                                            {...register("phoneNumber", registerOptions.phoneNumber)}
                                        />
                                        <small className="text-danger">
                                            {errors?.phoneNumber && errors.phoneNumber.message}
                                        </small>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                        <Form.Label className="mb-1 mb-md-3" as="legend">Correo</Form.Label>
                                        <Form.Control 
                                            name="clientEmail" 
                                            className='mb-4 mb-md-0'
                                            type='email'
                                            placeholder="Correo"
                                            {...register("clientEmail", registerOptions.clientEmail)}
                                        />
                                        <small className="text-danger">
                                            {errors?.clientEmail && errors.clientEmail.message}
                                        </small>
                                    </Form.Group>

                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} sm={6} controlId="formGridVia">
                                        <fieldset>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label className="mb-1 mb-md-3" as="legend">
                                                    ¿Tipo de piel?
                                                </Form.Label>
                                                <Col>
                                                {skinTypeOptions.map((option, index) => (
                                                    <Form.Check
                                                        key={index}
                                                        type="radio"
                                                        label={option}
                                                        name="skinType"
                                                        value={option}
                                                        {...register("skinType", registerOptions.skinType)}
                                                    />
                                                ))}
                                                </Col>
                                                <small className="text-danger">
                                                    {errors?.skinType && errors.skinType.message}
                                                </small>
                                            </Form.Group>
                                        </fieldset>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6} className="mb-3" controlId="formHorizontalPassword">
                                        <Form.Label className="mb-1 mb-md-3" as="legend">
                                            ¿Se ha realizado anteriormente alguno de estos procesos en las cejas?
                                        </Form.Label>
                                        <Col>
                                            {lastBrowsProcessOptions.map((option, index) => (
                                                <Form.Check
                                                    key={index}
                                                    name="lastBrowsProcess" 
                                                    type="checkbox" 
                                                    label={option}
                                                    value={option}
                                                    {...register("lastBrowsProcess", registerOptions.lastBrowsProcess)}
                                                />
                                            ))}
                                        </Col>
                                        <small className="text-danger">
                                            {errors?.lastBrowsProcess && errors.lastBrowsProcess.message}
                                        </small>
                                    </Form.Group>

                                </Row>
                                <Row>
                                <Form.Group as={Col} sm={12} className="mb-3" controlId="formHorizontalCheck">
                                        <Form.Label className="mb-1 mb-md-3" as="legend">
                                            Padece o ha padecido alguna de estas condiciones?
                                        </Form.Label>
                                        
                                        <Col className='two-columns'>
                                        {conditionsOptions.map((option, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                label={option}
                                                value={option}
                                                name="conditions"
                                                {...register("conditions", registerOptions.conditions)}
                                            />
                                        ))}
                                            
                                        </Col>
                                        
                                        <small className="text-danger">
                                            {errors?.conditions && errors.conditions.message}
                                        </small>
                                    </Form.Group>
                                    
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} controlId="formGridMessage">
                                        <Form.Label className="mb-1 mb-md-3" as="legend">En caso de haberse realizado uno de los procesos anteriores, hace cuanto fue su último retoque?</Form.Label>
                                        <Form.Control 
                                            name="generalMessage" 
                                            className='mb-4 mb-md-0'
                                            as="textarea" 
                                            rows={4} 
                                            {...register("generalMessage")}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3 justify-content-center'>
                                    <Form.Group as={Row} sm={10} controlId="formSend">
                                        {isSubmitting ? <Spinner animation="border" variant="primary" /> : (
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                Enviar
                                            </Button>
                                        )}
                                    </Form.Group>
                                </Row>            
                        
                            </Form>
                        </Row>
                    </Col>
                    <Col className='order-0 order-md-1'>
                        <Form.Group as={Col} sm={12} className="mb-3" controlId="formHorizontalCheck">
                            <Form.Label className="mb-1 mb-md-3" as="legend">
                                ¿Cuál procedimiento desea realizarse?
                            </Form.Label>
                            <Row>
                                <Col sm={12}>
                                    {data.permanent_makeup_services.map((service, index) => (
                                        <Form.Group 
                                        key={index} 
                                        className={`mx-1 radio-img ${selectedService === service.name ? 'selected' : ''}`}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                id={`service-${index}`}
                                                value={service.name}
                                                name="desiredProcess"
                                                {...register("desiredProcess", registerOptions.desiredProcess)}
                                                onClick={() => setSelectedService(service.name)}
                                                style={{ display: 'none' }}
                                                />
                                                <label htmlFor={`service-${index}`}>
                                                    <div>{service.name}</div>
                                                </label>
                                        </Form.Group>
                                    ))}
                                </Col>
                                <Col sm={12}>
                                    <div className="service-description">
                                        {data.permanent_makeup_services.filter(service => service.name === selectedService).map((service, index) => (
                                            <div key={index}>
                                                    <img
                                                        className='img-fluid mx-5 my-3' 
                                                        src={service.image}
                                                        alt={service.name}
                                                        />
                                                    <button className='btn btn-secondary mx-5' onClick={() => handleCheckboxChange(service.name)}>
                                                        {checkedOptions && Object.keys(checkedOptions).filter(serviceName => checkedOptions[serviceName]).includes(service.name) ? 'Deselccionar' : 'Seleccionar'}
                                                    </button>
                                                    <Col>
                                                        <h3>{service.name}</h3>
                                                        <p className='text-start' dangerouslySetInnerHTML={{ __html: service.description}}></p>
                                                    </Col>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                            <small className="text-danger">
                                {selectedService && selectedService.length === 0 ? 'El servicio a evaluar debe ser seleccionado' : ''}
                            </small>
                        </Form.Group>
                    </Col>
                </Row>
            )}
            <Row className='mt-3 mt-sm-5'>
                <p className='mt-3 mt-sm-5'>Nuestro equipo de Servicio al Cliente le estará contactando a la mayor brevedad para enviarle la confirmación de su cita vía WhatsApp o llamada telefónica. Debe esperar esta confirmación para asegurar su cita. En caso de no ser contactada en 24hrs favor escribirnos al 829.729.4779. Gracias por preferirnos!</p>
            </Row>
        </Container>
    );
};

export default ServiceEvaluationForm;
