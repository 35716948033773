const data = {
  logo: "/assets/paomakeup/images/logo.png",
  title: "Paomakeup Beauty Studio",
  description: "Somos un equipo de profesionales enfocados en la belleza. Hemos creado este centro ofreciendo la mejor asistencia en el área de maquillaje, peinados y otros servicios que demanda la mujer de hoy en día.",
  aboutus_title: "¿Quiénes Somos?",
  aboutus_image: "assets/paomakeup/images/paomakeup-person.jpg",
  aboutus_body: "<p>Somos un equipo de profesionales enfocados en la belleza. Hemos creado este centro ofreciendo la mejor asistencia en el área de maquillaje, peinados y otros servicios que demanda la mujer de hoy en día.</p>" +
      "<p>Nos enfocamos en realzar la belleza de manera natural, ayudando a nuestros clientes a sentirse a gusto con su imagen para cualquier ocasión. Utilizamos productos de calidad adecuados a su necesidad, con el objetivo de proporcionarles el mejor de los servicios con un trato personalizado.</p>" +
      "<h4>Nuestro compromiso es ayudarte a realzar tu belleza para que salgas a brillar!</h4>" +
      "<h5>Paola Muñoz</h5>",
  month_offers: [
    {
      title: "Promoción de Mes",
      description: "¡Aprovecha nuestra promoción de mes! Descubre nuestros servicios y disfruta de un 10% de descuento en tu primera visita.",
      image: "assets/paomakeup/ofertas/Oferta-Julio-2024-Paomakeup-Beauty-Combos.jpg"
    },
    {
      title: "Promoción de Mes",
      description: "¡Aprovecha nuestra promoción de mes! Descubre nuestros servicios y disfruta de un 10% de descuento en tu primera visita.",
      image: "assets/paomakeup/ofertas/Oferta-Julio-2024-Paomakeup-Cejas.jpg"
    },
  ],
  our_services: [
    {
      title: "Permanent Makeup",
      service_slug: 'permanent-makeup',
      description: "¿Te imaginas no tener que maquillarte a diario? Esto es posible con nuestros procedimientos de maquillaje semipermanente con aspecto natural.",
      image: "assets/paomakeup/images/services/microblading.jpg"
    },
    {
      title: "Beauty Makeup",
      service_slug: 'beauty-makeup',
      description: "Servicio de Maquillaje Profesional de la mano de nuestras profesionales, ideal para cualquier ocasión en la que quieras realzar tu belleza.",
      image: "assets/paomakeup/images/services/service-makeup.jpeg"
    },
    {
      title: "Beauty Hair",
      service_slug: 'beauty-hair',
      description: "Deja tu cabello en manos expertas y cuidados profesionales en nuestra área de peluquería, te brindamos estos servicios.",
      image: "assets/paomakeup/images/services/service-salon.jpeg"
    },
    {
      title: "Beauty Lashes",
      service_slug: 'beauty-lashes-brows',
      description: "Lista para una mirada impactante?? Alarga y destaca tus pestañas, con cualquiera de estos servicios te verás fabulosa.",
      image: "assets/paomakeup/images/services/beauty-lashes.jpg"
    },
    {
      title: "Beauty Brows",
      service_slug: 'beauty-lashes-brows',
      description: "Las cejas son el marco del rostro, permite que diseñemos la forma que mejor se adapta a ti para lograr Cejas Perfectas y una mirada impactante!.",
      image: "assets/paomakeup/images/services/beauty-brows.jpg"
    },
    {
      title: "Beauty Face",
      service_slug: 'beauty-face-body',
      description: "Visita nuestra cabina de belleza, donde las manos expertas de nuestra cosmiatra te ayudará a lucir una piel radiante todos los días.",
      image: "assets/paomakeup/images/services/beauty-face.jpg"
    },
    {
      title: "Beauty Nails",
      service_slug: 'beauty-nails',
      description: "Relájate al mismo tiempo que hidratas y cuidas tus manos y pies con nuestros productos naturales provenientes de rosas.",
      image: "assets/paomakeup/images/services/service-beauty-nails.jpg"
    }
  ],
  permanent_makeup_services: [
    {
      name: "Microblading",
      service_slug: 'perfect-brows',
      description: "<div>"+
        "<p>Es la &uacute;ltima t&eacute;cnica dentro de la industria del maquillaje permanente en la creaci&oacute;n de dise&ntilde;o de cejas pelo a pelo, m&eacute;todo de f&aacute;cil realizaci&oacute;n, r&aacute;pida cicatrizaci&oacute;n y de aspecto muy natural.</p>"+
        "<p>Indicado para aquellas personas que desean rellenar algunas zonas y definir naturalmente sus cejas.</p>"+
        "<ul>"+
        "<li>⌛️ Duraci&oacute;n: 1 a&ntilde;o aproximadamente</li>"+
        "<li>💵 Costo: US$350</li>"+
        "<li>📆 Retoques: 30 d&iacute;as: US$50 - 45 d&iacute;as: US$75 - 60 d&iacute;as: US$100</li>"+
        "<li>⏰ Tiempo del proceso: 1.5 horas</li>"+
        "<li>😩 Nivel de Dolor: 2/10 (se utiliza anestesia t&oacute;pica)</li>"+
        "</ul>"+
      "</div>",
      image: "assets/paomakeup/images/services/permanent-makeup-1.jpg"
    },
    {
      name: "Shading Digital",
      service_slug: 'shade-digital',
      description: "<div>"+
        "<p>También llamado Powder Brows (efecto tipo polvo). El sombreado semipermanente de cejas, tambi&eacute;n llamado micropigmentaci&oacute;n, es una t&eacute;cnica a trav&eacute;s de la cual se introducen pigmentos a nivel epid&eacute;rmico de forma indolora creando un efecto sombreado en sus cejas.</p>"+
        "<p>Ideal para personas con pieles grasas o con cicatrizaci&oacute;n queloides.</p>"+
        "<ul>"+
        "<li>⌛️ Duraci&oacute;n: 1 a&ntilde;o aproximadamente</li>"+
        "<li>💵 Costo: US$290</li>"+
        "<li>📆 Retoques: 30 d&iacute;as: US$50 - 45 d&iacute;as: US$75 - 60 d&iacute;as: US$100</li>"+
        "<li>⏰ Tiempo del proceso: 1.5 horas</li>"+
        "<li>😩 Nivel de Dolor: 2/10 (se utiliza anestesia t&oacute;pica)</li>"+
        "</ul></div>",
      image: "assets/paomakeup/images/services/permanent-makeup-2.jpg"
    },
    {
      name: "Perfect Brows",
      service_slug: 'perfect-brows',
      description: "<div>"+
        "<p>T&eacute;cnica combinada de microblanding y powder brows. Esta t&eacute;cnica combina el pelo a pelo con el sombreado digital en degradado, ideal para las que les gusta el efecto de cejas m&aacute;s pobladas y definidas.</p>"+
        "<p>Adecuado para pieles oleosas y personas que tienen cejas poco pobladas o anteriormente tatuadas.</p>"+
          "<ul>"+
            "<li>⌛️ Duraci&oacute;n: 1-2 a&ntilde;os aproximadamente</li>"+
            "<li>💵 Costo: US$350</li>"+
            "<li>📆 Retoques: 30 d&iacute;as: US$50 - 45 d&iacute;as: US$75 - 60 d&iacute;as: US$100</li>"+
            "<li>⏰ Tiempo del proceso: 1.5 horas</li>"+
            "<li>😩 Nivel de Dolor: 2/10 (se utiliza anestesia t&oacute;pica)</li>"+
          "</ul>"+
        "</div>",
      image: "assets/paomakeup/images/services/permanent-makeup-3.jpg"
    },
    {
      name: "Microshading",
      service_slug: 'microshading',
      description: "<div>"+
        "<p>Cobertura de Tatuajes. Con esta t&eacute;cnica neutralizamos el color de su tatuaje anterior y luego procedemos a pigmentar la piel con el tono y forma deseados.</p>"+
        "<p>NOTA: El procedimiento anterior debe haber aclarado al menos un 70% y ser evaluada para determinar si aplica a esta t&eacute;cnica.</p>"+
        "<ul>"+
          "<li>⌛️ Duraci&oacute;n: 1 a&ntilde;o aproximadamente</li>"+
          "<li>💵 Costo: US$450</li>"+
          "<li>📆 Retoques: 30 d&iacute;as: US$50 - 45 d&iacute;as: US$75 - 60 d&iacute;as: US$100</li>"+
          "<li>⏰ Tiempo del proceso: 1.5 horas</li>"+
          "<li>😩 Nivel de Dolor: 2/10 (se utiliza anestesia t&oacute;pica) (puede que sea necesario realizar 2 retoques)</li>"+
        "</ul>"+
      "</div>",
      image: "assets/paomakeup/images/services/permanent-makeup-4.jpg"
    },
    {
      name: "Micropigmentación de Labios",
      service_slug: 'micropigmentacion-labios',
      description: "<div>"+
        "<p>En los labios, la micropigmentaci&oacute;n sirve para equilibrar peque&ntilde;as asimetr&iacute;as, redise&ntilde;ar el contorno que se pierde con el paso del tiempo y colorear la mucosa, ayudando a mantener los labios jugosos.</p>"+
        "<p>Si sus labios tienen pigmentaci&oacute;n oscura se debe realizar primero una neutralizaci&oacute;n. (A evaluar).</p>"+
        "<ul>"+
          "<li>⌛️ Duraci&oacute;n: 1-2 a&ntilde;os aproximadamente</li>"+
          "<li>💵 Costo: US$350</li>"+
          "<li>📆 Retoques: 30 d&iacute;as: US$50 - 45 d&iacute;as: US$75 - 60 d&iacute;as: US$100</li>"+
          "<li>⏰ Tiempo del proceso: 1.5 horas</li>"+
          "<li>😩 Nivel de Dolor: 2/10 (se utiliza anestesia t&oacute;pica).</li>"+
        "</ul>"+
      "</div>",
      image: "assets/paomakeup/images/services/permanent-makeup-5.jpg"
    },
    {
      name: "Neutralización de Labios",
      service_slug: 'neutralizacion-labios',
      description: "<div>"+
        "<p>Si tiene labios ocuros, esta t&eacute;cnica tiene como objetivo unificar el tono de los labios, creando un color uniforme.</p>"+
        "<ul>"+
          "<li>⌛️ Duraci&oacute;n: 1-2 a&ntilde;os aproximadamente</li>"+
          "<li>💵 Costo: US$350</li>"+
          "<li>📆 Retoques: 30 d&iacute;as: US$50 - 45 d&iacute;as: US$75 - 60 d&iacute;as: US$100</li>"+
          "<li>⏰ Tiempo del proceso: 1.5 horas</li>"+
          "<li>😩 Nivel de Dolor: 2/10 (se utiliza anestesia t&oacute;pica)</li>"+
        "</ul>"+
      "</div>",
      image: "assets/paomakeup/images/services/permanent-makeup-6.jpg"
    }
  ],
  our_services_price_title: "Nuestros Servicios y Precios",
  our_services_price_description: "Precios de nuestros servicios de maquillaje, cejas, pestañas, uñas, cabello, rostro, cuerpo, belleza, spa, estetica.",
  our_services_price_keywords: "servicios, precios, maquillaje, cejas, pestañas, uñas, cabello, rostro, cuerpo, belleza, spa, estetica, santo domingo, república dominicana",
  our_services_price: [
    {
      service_name: 'BEAUTY MAKEUP',
      service_slug: 'beauty-makeup',
      service_image: 'assets/paomakeup/images/services/service-makeup.jpeg',
      bgcolor: 'card6',
      services: [
        { name: 'Maquillaje Express', price: 'RD$ 1,500.00'},
        { name: 'Maquillaje Soft', price: 'RD$ 2,000.00'},
        { name: 'Maquillaje Glam', price: 'RD$ 2,500.00'},
        { name: 'Maquillaje de Novia *Piel Blindada', price: 'RD$ 4,000.00'},
        { name: 'Maquillaje Sesion de Fotos * Piel Blindada', price: 'RD$ 4,000.00'},
        { name: 'Maquillaje Caballeros', price: 'RD$ 800.00'},
        { name: 'Maquillaje de Cejas', price: 'RD$ 500.00'},
        { name: 'Maquillaje de Fantasia Sencillo', price: 'RD$ 3,500.00'},
        { name: 'Maquillaje de Fantasia Elaborado', price: 'RD$ 4,500.00'},
        { name: 'Maquillaje de Ojos Sencillo*Sin Pestañas', price: 'RD$ 1,200.00'},
        { name: 'Maquillaje de Ojos con Pestañas', price: 'RD$ 1,500.00'},
        { name: 'Maquillaje de Ojos Express (delineado y cejas)', price: 'RD$ 950.00'},
        { name: 'Maquillaje Niñas Grandes (hasta 13 años)', price: 'RD$ 1,200.00'},
        { name: 'Maquillaje Niñas Pequeñas (5-10 años)', price: 'RD$ 800.00'},
      ]
    },
    {
      service_name: 'PERMANET MAKEUP',
      service_slug: 'permanent-makeup',
      service_image: 'assets/paomakeup/images/services/microblading.jpg',
      bgcolor: 'card1',
      services: [
        { name: 'Shading Digital (Powder Brows)', price: 'US$ 250' },
        { name: 'Microblading', price: 'US$ 300' },
        { name: 'Perfect Brows (Microblading + Shading digital)', price: 'US$ 350' },
        { name: 'Microshading (Cobertura Procesos Anteriores)', price: 'US$ 450' },
        { name: 'Neutralizacion de Labios', price: 'US$ 300' },
        { name: 'Micropigmentación de Labios', price: 'US$ 300' },
        { name: 'Neutralizacion + Micropigmentacion de Labios', price: 'US$ 500' },
        { name: 'Retoque a los 30 días', price: 'US$ 50'},
        { name: 'Retoque a los 60 días', price: 'US$ 75'},
        { name: 'Retoque a los 90 días', price: 'US$ 100'},
      ]
    },
    {
      service_name: 'BEAUTY LASHES & BROWS',
      service_slug: 'beauty-lashes-brows',
      service_image: 'assets/paomakeup/images/services/beauty-lashes.jpg',
      bgcolor: 'card2',
      services: [
        { name: 'Brow Lamination', price: 'RD$ 3,000.00'} ,
        { name: 'Brow Lamination by Paola Muñoz', price: 'RD$ 4,000.00'} ,
        { name: 'Cejas Perfectas', price: 'RD$ 1,200.00'} ,
        { name: 'Cejas Perfectas by PM', price: 'RD$ 2,000.00'} ,
        { name: 'Depilación de Barbilla Cera/Hilo', price: 'RD$ 500.00'} ,
        { name: 'Depilación de Cejas con Cera/Hilo', price: 'RD$ 700.00'} ,
        { name: 'Depilación de Cejas con Hilo by PM', price: 'RD$ 1,200.00'} ,
        { name: 'Depilación de Cejas con Pinza', price: 'RD$ 500.00'} ,
        { name: 'Depilación de Labio Superior con Hilo/Cera', price: 'RD$ 500.00'} ,
        { name: 'Depilación Rostro ', price: 'RD$ 1,500.00'} ,
        { name: 'Lash Lift (Realce de Pestañas)', price: 'RD$ 3,000.00'} ,
        { name: 'Lash Lift by PM', price: 'RD$ 4,000.00'} ,
        { name: 'Perfilado de Cejas con Navaja', price: 'RD$ 400.00'} ,
        { name: 'Postura de Pestañas del Cliente', price: 'RD$ 350.00'} ,
        { name: 'Pestañas Corridas', price: 'RD$ 500.00'} ,
        { name: 'Pestañas Individuales por Grupo', price: 'RD$ 995.00'} ,
        { name: 'Pestañas Pelo a Pelo', price: 'RD$ 3,000.00'} ,
        { name: 'Pestañas Pelo a Pelo by PM', price: 'RD$ 4,000.00'} ,
        { name: 'Pestañas Volumen Ruso', price: 'RD$ 4,000.00'} ,
        { name: 'Pestanas Extra Volumen Ruso', price: 'RD$ 5,000.00'} ,
        { name: 'Pestañas Volumen Ruso by PM', price: 'RD$ 5,000.00'} ,
        { name: 'Pestañas Extra Volumen Ruso by PM', price: 'RD$ 6,000.00'} ,
        { name: 'Retiro de Pestañas Pelo a Pelo', price: 'RD$ 800-1500'} ,
        { name: 'Retiro de Pestañas por Grupo', price: 'RD$ 600-1,500'} ,
        { name: 'Retoque de Pestañas Pelo a Pelo 1 semana', price: 'RD$ 1,500.00'} ,
        { name: 'Retoque de Pestañas Pelo a Pelo 2 semanas', price: 'RD$ 2,000.00'} ,
        { name: 'Retoque de Pestañas Pelo a Pelo 3 semanas', price: 'RD$ 2,500.00'} ,
        { name: 'Retoque de Petañas Volumen Ruso 1 semana', price: 'RD$ 2,000.00'} ,
        { name: 'Retoque de Petañas Volumen Ruso 2 semanas', price: 'RD$ 2,500.00'} ,
        { name: 'Retoque de Petañas Volumen Ruso 3 semanas', price: 'RD$ 3,000.00'} ,
        { name: 'Tintado de Cejas', price: 'RD$ 700.00'} ,
        { name: 'Tintado de Cejas by PM', price: 'RD$ 1,000.00'} ,
      ]
    },
    {
      service_name: 'BEAUTY HAIR',
      service_slug: 'beauty-hair',
      service_image: 'assets/paomakeup/images/services/service-salon.jpeg',
      bgcolor: 'card3',
      services: [
        { name: 'Botox Capilar Orgánico onz', price: '2,200.00'},
        { name: 'Cirugia Capilar DEYA onz', price: '2,800.00'},
        { name: 'Corte de Pollina', price: '800.00'},
        { name: 'Corte de Pelo ', price: '2,500.00'},
        { name: 'Corte de Puntas ', price: '1,500.00'},
        { name: 'Lavado con Linea (Antigrasa, Diamond)', price: '500.00'},
        { name: 'Lavado con Linea Moroccanoil', price: '700.00'},
        { name: 'Lavado Pelo Corto', price: '400.00'},
        { name: 'Lavado Pelo Mediano/Largo', price: '500.00'},
        { name: 'Lavado Pelo Extra Largo', price: '600.00'},
        { name: 'Lavado y Secado Pelo Extra Corto', price: '600.00'},
        { name: 'Lavado y Secado Pelo Corto (Cuello)', price: '700.00'},
        { name: 'Lavado y Secado Pelo Mediano/Largo (Hombros/Espalda)', price: '800.00'},
        { name: 'Lavado y Secado Pelo Extra Largo', price: '900.00'},
        { name: 'Leaving', price: '200.00'},
        { name: 'Mascarilla', price: '500.00'},
        { name: 'Masaje Craneal Relax', price: '500.00'},
        { name: 'Ondas Pelo Corto', price: '800.00'},
        { name: 'Ondas Pelo Mediano/Largo', price: '1,000.00'},
        { name: 'Ondas Pelo Extra Largo', price: '1,500.00'},
        { name: 'Peinado de Novia', price: '2,000.00'},
        { name: 'Peinado Elaborado ', price: '1,500.00'},
        { name: 'Peinado Sencillo', price: '1,000.00'},
        { name: 'Peinado Niña', price: '800.00'},
        { name: 'Planchado de Pelo Corto', price: '400.00'},
        { name: 'Planchado de Pelo Mediano/Largo', price: '500.00'},
        { name: 'Planchado de Pelo Extra/Largo', price: '600.00'},
        { name: 'Prueba de Peinado de Novia', price: '2,000.00'},
        { name: 'Secado de Pelo Corto', price: '500.00'},
        { name: 'Secado Pelo Mediano/Largo', price: '600.00'},
        { name: 'Secado Pelo Extra Largo', price: '700.00'},
        { name: 'Secado Ondas con Cepillo (Corto/Medio)', price: '500.00'},
        { name: 'Split Ender Pro - Pelo Corto*', price: '1,000.00'},
        { name: 'Split Ender Pro - Pelo Mediano/Largo*', price: '1,200.00'},
        { name: 'Split Ender Pro - Pelo Largo*', price: '1,500.00'},
        { name: 'Tinte Completo Cabello Corto', price: '2,500.00'},
        { name: 'Tinte Completo Cabello Medio', price: '3,000.00'},
        { name: 'Tinte Completo Cabello Largo', price: '3,500.00'},
        { name: 'Tinte Completo Cabello Extra Largo', price: '4,000.00'},
        { name: 'Tinte Retoque Cintillo', price: '1,500.00'},
        { name: 'Tinte Retoque Raiz', price: '2,500.00'},
        { name: 'Tratamiento en el Secador Cliente', price: '300.00'},
        { name: 'Tratamiento Profundo', price: '1,000.00	'} ,
      ]
    },
    {
      service_name: 'BEAUTY FACE & BODY',
      service_slug: 'beauty-face-body',
      service_image: 'assets/paomakeup/images/services/beauty-face.jpg',
      bgcolor: 'card4',
      services: [
        { name: 'Spa de Ojos', price: 'RD$ 650.00'} ,
        { name: 'Facial Express * Sin Extracción', price: 'RD$ 1,500.00'} ,
        { name: 'Facial Detox', price: 'RD$ 2,500.00'} ,
        { name: 'Facial Limpieza Profunda', price: 'RD$ 3,500.00'} ,
        { name: 'Facial Hidratación Profunda', price: 'RD$ 3,500.00'} ,
        { name: 'Dermaplaning', price: 'RD$ 3,000.00'} ,
        { name: 'Hydralips', price: 'RD$ 4,500.00'} ,
        { name: 'Microneedling (Dermapen) ', price: 'RD$ 4,000.00'} ,
        { name: 'Peeling Químico', price: 'RD$ 2,000.00'} ,
        { name: 'Masaje Facial de Relajación', price: 'RD$ 500.00'} ,
        { name: 'Ampolla hidratante', price: 'RD$ 300.00'} ,
        { name: 'Peeling Corporal  ', price: 'RD$ 2,500.00'} ,
        { name: 'Blanqueamiento de Axilas', price: 'RD$ 1,500.00'} ,
      ]
    },
    {
      service_name: 'BEAUTY NAILS',
      service_slug: 'beauty-nails',
      service_image: 'assets/paomakeup/images/services/service-beauty-nails.jpg',
      bgcolor: 'card5',
      services: [
        { name: 'Manicure Express en Seco', price: 'RD$ 400.00'} ,
        { name: 'Pedicure Express en Seco', price: 'RD$ 500.00'} ,
        { name: 'Pintura en Gel Caliente Manos', price: 'RD$ 700.00'} ,
        { name: 'Pintura en Gel Caliente Pies', price: 'RD$ 800.00'} ,
        { name: 'Pintura en Gel Frio Manos', price: 'RD$ 400.00'} ,
        { name: 'Pintura en Gel Frio Pies', price: 'RD$ 450.00'} ,
        { name: 'Pintura Manos', price: 'RD$ 300.00'} ,
        { name: 'Pintura Pies', price: 'RD$ 350.00'} ,
        { name: 'Retiro de Gel Pies', price: 'RD$ 400.00'} ,
        { name: 'Retiro Gel Manos', price: 'RD$ 350.00'} ,
      ]
    }
  ],
  homepage_carousel: [
    {
      id: 1,
      image: '../assets/paomakeup/images/services/microblading.jpg',
      alt: 'Permanent Makeup',
      title: 'Permanent Makeup',
      description: 'Te imaginas no tener que maquillarte a diario? Esto es posible con nuestros procedimientos de maquillaje semipermanente y podrás tener unas cejas perfectas y con un aspecto muy natural.'
    },
    {
      id: 2,
      image: '../assets/paomakeup/images/services/maquillaje-cliente.jpg',
      alt: 'Makeup',
      title: 'Maquillaje',
      description: 'Descubre el arte del maquillaje que resalta tu belleza intrínseca.\nYa sea para una ocasión especial o un look diario, te guiaremos hacia la perfección en cada trazo y color.'
    },
    {
      id: 3,
      image: '../assets/paomakeup/images/services/pestanas.jpg',
      alt: 'Beauty Lashes',
      title: 'Beauty Lashes',
      description: 'Eleva tu mirada con nuestras extensiones de pestañas. Desde un toque sutil hasta un glamour completo, cada pestaña cuenta una historia de elegancia.'
    },
    {
      id: 4,
      image: '../assets/paomakeup/images/services/salon-belleza.jpg',
      alt: 'Salón de Belleza',
      title: 'Salón de Belleza',
      description: 'Sumérgete en nuestro salón de belleza. Un santuario donde la relajación se combina con la transformación, servicios excepcionales que realzan tu belleza.'
    }
  ],
  address: 'Ave. Roberto Pastoriza, Plaza La Lira I, 2do Nivel,' +
      '\n' +
      'Santo Domingo, DN\n' +
      '\n' +
      'República Dominicana',
  phone: '809-547-4779',
  whatsapp: '829-729-4779',
  email: 'info@paomakeup.com',
  facebook: 'https://www.facebook.com/PaoMakeupBeautyStudio/?ref=br_rs',
  twitter: '',
  instagram: 'https://www.instagram.com/paomakeupstudio',
  linkedin: '',
  youtube: 'https://www.youtube.com/channel/UCLzJpdrplA9Oy6K1tVf8ZHw',
  tiktok: 'https://www.tiktok.com/paomakeup',
  // Add more data as needed
};

export default data;
