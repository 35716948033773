import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

const Video = (props) => {
    return (
        <Container fluid className="py-3 py-sm-5">
            <Container className="my-5 animate__animated animate__slideInUp">
                <Row>
                    <Col className='col-12 col-sm-4'>
                        <div className="embed-responsive embed-responsive-16by9 video-size">
                            <iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/${props.videoId}?feature=oembed`} title={props.title} allowFullScreen></iframe>
                        </div>
                    </Col>
                    <Col className='align-self-center'>
                        <h1 className="text-center display-2 mt-4 mt-md-0">{props.title}</h1>
                    </Col>
                </Row>

            </Container>
        </Container>
    );
}

export default Video;
