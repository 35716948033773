import React from 'react';
import data from "../data/paomakeup";
import { Card, Col, Container, Row } from 'react-bootstrap';

const MonthOffers = () => {
    if (!data.month_offers || data.month_offers.length === 0) {
        return;
    }

    return (
        <div className="month-offers">
                <Container className="my-5">
                <h1>Ofertas del mes</h1>
                <Row>
                    {data.month_offers.map((offer, index) => (
                        <Col key={index} sm={12} md={6} className="mt-4">
                            <Card className='border-0 mx-lg-4 animate__animated animate__fadeInUp'>
                                <Card.Img variant="top" src={offer.image} />
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
             </div>
    );
};

export default MonthOffers;