import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const CallToAction = () => {
    return (
        <Container fluid className='my-5 py-3 py-sm-5 bg-light'>
            <Container className='my-5 bg-light'>
                <Row className="justify-content-md-center">
                    <Col md="auto" style={{ textAlign: 'center', padding: '20px' }}>
                        <h2>¿Lista para empezar a brillar?</h2>
                        <p>Agenda una cita con nosotros para que salgas lista para la fiesta.</p>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md="4" style={{ textAlign: 'center', padding: '20px' }}>
                        <p>Cotiza tu evento con nosotros y te ayudaremos a lucir espectacular</p>
                        <a href="/cotiza-tu-evento" className="btn btn-primary border-0 px-3">Cotiza tu evento</a>
                    </Col>
                    <Col md="4" style={{ textAlign: 'center', padding: '20px' }}>
                        <p>Visita nuestro studio y permítenos embellecerte</p>
                        <a href="/agendar-cita" className="btn btn-primary border-0 px-3">Agendar cita</a>
                    </Col>
                    <Col md="4" style={{ textAlign: 'center', padding: '20px' }}>
                        <p>Evalúate gratis para nuestros servicios de maquillaje semipermanente</p>
                        <a href="/evaluacion-para-maquillaje-semipermanente" className="btn btn-primary border-0 px-3">Solicitar evaluación</a>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default CallToAction;