import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import data from '../data/paomakeup';

const Header = () => {
    return (
        <Container>
            <Navbar className="gap-3 px-4 mt-4" bg="transparent" expand="md">
                <Navbar.Brand href="/">
                    <img
                        src={data.logo}
                        className="align-top brand-logo"
                        alt={data.title}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
                    <Nav className="flex-grow-1 justify-content-end align-items-center">
                        <Nav.Item className='mx-md-4'>
                            <Nav.Link href="/">Inicio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mx-md-4'>
                            <Nav.Link href="/nosotros">Nosotros</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mx-md-4'>
                            <Nav.Link href="/nuestros-servicios">Servicios</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mx-md-4'>
                            <Nav.Link href="/nuestros-servicios/precios">Precios</Nav.Link>
                        </Nav.Item>
                       <Nav.Item className="btn btn-primary">
                            <Nav.Link href="/agendar-cita" className="btn btn-primary border-0 px-3">Agendar cita</Nav.Link>
                       </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
};

export default Header;
