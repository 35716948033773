import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube, faTiktok, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import data from '../data/paomakeup';

const Footer = () => {

    return (
        <Container fluid style={{backgroundColor: '#f8f9fa', textAlign: 'center', padding: '10px'}}>
            <footer className="text-center text-lg-start bg-body-tertiary text-muted">
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    <div className="me-5 d-none d-lg-block">
                        <span>Visita nuestras redes sociales:</span>
                    </div>
                    <div>
                        <a href={data.facebook} className="me-4 text-reset">
                            <FontAwesomeIcon icon={faFacebook} size="2xl" />
                        </a>
                        <a href={data.instagram} className="me-4 text-reset">
                            <FontAwesomeIcon icon={faInstagram} size="2xl" />
                        </a>
                        <a href={data.youtube} className="me-4 text-reset">
                            <FontAwesomeIcon icon={faYoutube} size="2xl" />
                        </a>
                        <a href={data.tiktok} className="me-4 text-reset">
                            <FontAwesomeIcon icon={faTiktok} size="2xl" />
                        </a>
                    </div>
                </section>

                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    <i className="fas fa-gem me-3"></i>{data.title}
                                </h6>
                                <p>
                                    ¡Salga a brillar con nuestros servicios!
                                </p>
                            </div>

                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Nosotros
                                </h6>
                                <p>
                                    <a href="/nosotros" className="text-reset">Nosotros</a>
                                </p>
                                <p>
                                    <a href="/nuestros-servicios" className="text-reset">Servicios</a>
                                </p>
                                <p>
                                    <a href="/agendar-cita" className="text-reset">Contactenos</a>
                                </p>
                                <p>
                                    <a href="/agendar-cita" className="text-reset">Agendar cita</a>
                                </p>
                            </div>

                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Servicios
                                </h6>
                                <p>
                                    <a href="/nuestros-servicios/precios/beauty-lashes-brows" className="text-reset">Microblading</a>
                                </p>
                                <p>
                                    <a href="/nuestros-servicios/precios/beauty-lashes-brows" className="text-reset">Pestañas</a>
                                </p>
                                <p>
                                    <a href="/nuestros-servicios/precios/beauty-makeup" className="text-reset">Makeup</a>
                                </p>
                                <p>
                                    <a href="/nuestros-servicios/precios/beauty-hair" className="text-reset">Beauty salon</a>
                                </p>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
                                <p>
                                    <FontAwesomeIcon icon={faLocationPin} className="me-3" />
                                        <p className="mb-0">{data.address}</p>
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faEnvelope} className="me-3" />
                                    {data.email}
                                </p>
                                <p><FontAwesomeIcon icon={faPhone} className="me-3" /> {data.phone}</p>
                                <p><FontAwesomeIcon icon={faWhatsapp} className="me-3" /> {data.whatsapp}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-4">
                    Desarrollado por:
                    <a className="text-reset fw-bold" href="https://www.coralanmultiservice.com/"> Coralan Multiservice</a>
                </div>
            </footer>
        </Container>
    );
};

export default Footer;
