import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import HomePage from './pages/HomePage';
import ContactUs from './pages/ContactUs';
import OurServices from './pages/OurServices';
import OurServicesPrices from './pages/OurServicesPrices';
import OurServicesSlug from './pages/OurServicesSlug';
import ScheduleCall from './pages/ScheduleCall';

import './scss/App.scss';
import ScrollToTop from './components/ScrollToTop';
import ServiceEvaluationForm from './pages/ServiceEvaluationForm';
import QuoteEvent from './pages/QuoteEventForm';
import WhatsappIcon from './components/WhatsappIcon';

const App = () => {
  return (
      <Router>
        <ScrollToTop />
        <div className="App">
          <Header />
          <Routes>
            <Route path="/nosotros" element={<AboutUs />} />
            <Route path="/nuestros-servicios" element={<OurServices />} />
            <Route path="/nuestros-servicios/precios" element={<OurServicesPrices />} />
            <Route path="/nuestros-servicios/precios/:pageIdSlug" element={<OurServicesSlug />} />
            <Route path="/contactenos" element={<ContactUs />} />
            <Route path="/agendar-cita" element={<ScheduleCall />} />
            <Route path="/evaluacion-para-maquillaje-semipermanente" element={<ServiceEvaluationForm />} />
            <Route path="/cotiza-tu-evento" element={<QuoteEvent />} />
            <Route path="/" exact element={<HomePage />} />
          </Routes>
          <Footer />
          <WhatsappIcon />
        </div>
      </Router>
  );
}

export default App;
