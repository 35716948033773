import React from "react";
import Card from "react-bootstrap/Card"; 
import Row from "react-bootstrap/Row"; 
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import { useParams } from "react-router-dom";
// import Masonry from 'react-masonry-css'; // desktop
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"; // mobile
import "react-multi-carousel/lib/styles.css";
import data from "../data/paomakeup";
import { Helmet } from "react-helmet";

const breakpointColumnsObj = {
    default: 2,
    1100: 3,
    700: 1,
    385: 1
  };

const OurServicesSlug = () => {
    const { pageIdSlug } = useParams();
    const filteredServices = data.our_services_price.find((service) => service.service_slug === pageIdSlug);
console.log('filteredServices', filteredServices);
    let servicePriceElements;
        if (filteredServices) {
            servicePriceElements = (
            <div className="custom-service page animate__animated animate__bounceInRight">
                <Card bg={filteredServices.bgcolor.toLowerCase()} className="border-0 rounded-5" style={{ width: '100%' }}>
                    <Card.Header>{filteredServices.service_name}</Card.Header>
                    <Card.Img variant="top" src={filteredServices.image} />
                    <ListGroup variant="flush">
                        {filteredServices.services.map((item, index) => (
                            <ListGroup.Item key={index} style={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                backgroundColor: index % 2 === 0 ? '#ee9ac3' : '#f0c1d8',
                                }}>
                                <Col className="col-8 text-start">{item.name}</Col>
                                <Col className="col-4 text-end">{item.price}</Col>    
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card>
            </div>
        );
    };

    return (
        <Container className="my-5 g-sm-0">
            <Helmet>
                <title>{filteredServices.service_name} - {data.title}</title>
                <meta name="description" content={data.our_services_price_description} />
                <meta name="keywords" content={data.our_services_price_keywords} />
                <meta property="og:title" content={`Precios de ${filteredServices.service_name} - ${data.title}`} />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`/nuestros-servicios/precios/${filteredServices.service_slug}`} />
                <meta property="og:site_name" content="PaoMakeup Beauty Studio" />
                <meta property="og:description" content={data.our_services_price_description} />
            </Helmet>
            <h1 className="mx-sm-4 animate__animated animate__pulse mb-5">Conoce nuestros servicios y precios</h1>
            <Row>
                <Col className="mx-sm-4 col-sm-5 col-12">
                    <Image className="img-fluid rounded-5 mb-3" src={`../../../${filteredServices.service_image}`} />
                </Col>
                <Col>
                    {servicePriceElements}
                </Col>
            </Row>

        </Container>
    );
}

export default OurServicesSlug;
