import React from 'react';
import data from '../data/paomakeup';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const OurServices = () => {
    return (
        <Container className="my-5">
            <Helmet>
                <title>Nuestros servicios - {data.title}</title>
                <meta name="description" content={data.our_services_price_description} />
                <meta name="keywords" content={data.our_services_price_keywords} />
                <meta property="og:title" content={`Nuestros servicios - ${data.title}`} />
                <meta property="og:type" content="article"/>
                <meta property="og:url" content="/nuestros-servicios/precios" />
                <meta property="og:site_name" content="PaoMakeup Beauty Studio" />
                <meta property="og:description" content={data.our_services_price_description} />
            </Helmet>
            <h1>Nuestros servicios</h1>
            <Row>
                {data.our_services.map((service, index) => (
                    <Col key={index} sm={12} md={4} className="mt-4">
                        <Card className='border-0 mx-lg-4 animate__animated animate__fadeInUp'>
                            <Card.Img className='image-class' variant="top" src={service.image} />
                            <Card.Body>
                                <h3 className="card-title">{service.title}</h3>
                                <Card.Text>
                                    {service.description}
                                </Card.Text>
                                <Link to={`/nuestros-servicios/precios/${service.service_slug}`} className="btn btn-primary">
                                    Ver Precios
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default OurServices;
