import React from 'react';
import HomeCarousel from "../components/HomeCarousel";
import Video from "../components/Video";
import OurServices from "../components/OurServices";
import CallToAction from '../components/CallToAction';
import { Helmet } from "react-helmet";
import data from "../data/paomakeup";
import MonthOffers from '../components/MonthOffers';

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>{data.title}</title>
                <meta name="description" content={data.description} />
                <meta name="keywords" content={data.our_services_price_keywords} />
                <meta property="og:title" content={data.title} />
                <meta property="og:type" content="article"/>
                <meta property="og:url" content="/" />
                <meta property="og:site_name" content="PaoMakeup Beauty Studio" />
                <meta property="og:description" content={data.description} />
            </Helmet>
            <HomeCarousel />
            <MonthOffers offers={data.month_offers} />
            <OurServices />
            <CallToAction />
            <Video title="Visite nuestras instalaciones" videoId="AGuOx5VrHTY" />
        </>
    );
};

export default HomePage;
